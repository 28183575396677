// Generated by Framer (9f68555)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["s7cL3CSyf", "q9iKDLWOI"];

const serializationHash = "framer-4TAk6"

const variantClassNames = {q9iKDLWOI: "framer-v-lmw1kk", s7cL3CSyf: "framer-v-1famao6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {OFF: "q9iKDLWOI", ON: "s7cL3CSyf"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, kgfVBtQOq: click ?? props.kgfVBtQOq, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "s7cL3CSyf"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, kgfVBtQOq, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "s7cL3CSyf", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap16qua3n = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (kgfVBtQOq) {const res = await kgfVBtQOq(...args);
if (res === false) return false;}
setVariant("q9iKDLWOI")
})

const onTapruplaw = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (kgfVBtQOq) {const res = await kgfVBtQOq(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1famao6", className, classNames)} data-framer-name={"ON"} data-highlight layoutDependency={layoutDependency} layoutId={"s7cL3CSyf"} onTap={onTap16qua3n} ref={ref ?? ref1} style={{backgroundColor: "rgb(46, 216, 12)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} variants={{q9iKDLWOI: {backgroundColor: "var(--token-ed3b63a2-509e-4d49-8b32-4225b3c51ff5, rgb(95, 99, 108))"}}} {...addPropertyOverrides({q9iKDLWOI: {"data-framer-name": "OFF", onTap: onTapruplaw}}, baseVariant, gestureVariant)}><motion.div className={"framer-1d3y1hv"} data-framer-name={"Shape"} layoutDependency={layoutDependency} layoutId={"GmK8YOKAP"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.30000001192092896)"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4TAk6.framer-sapsw9, .framer-4TAk6 .framer-sapsw9 { display: block; }", ".framer-4TAk6.framer-1famao6 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-end; overflow: visible; padding: 4px; position: relative; width: 37px; }", ".framer-4TAk6 .framer-1d3y1hv { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 12px); position: relative; width: 12px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-4TAk6.framer-1famao6 { gap: 0px; } .framer-4TAk6.framer-1famao6 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-4TAk6.framer-1famao6 > :first-child { margin-left: 0px; } .framer-4TAk6.framer-1famao6 > :last-child { margin-right: 0px; } }", ".framer-4TAk6.framer-v-lmw1kk.framer-1famao6 { justify-content: flex-start; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 37
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"q9iKDLWOI":{"layout":["fixed","auto"]}}}
 * @framerVariables {"kgfVBtQOq":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerFlkPOfZJu: React.ComponentType<Props> = withCSS(Component, css, "framer-4TAk6") as typeof Component;
export default FramerFlkPOfZJu;

FramerFlkPOfZJu.displayName = "Button/Toggle";

FramerFlkPOfZJu.defaultProps = {height: 20, width: 37};

addPropertyControls(FramerFlkPOfZJu, {variant: {options: ["s7cL3CSyf", "q9iKDLWOI"], optionTitles: ["ON", "OFF"], title: "Variant", type: ControlType.Enum}, kgfVBtQOq: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerFlkPOfZJu, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})